import axios from "@axios";

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

function fetchAll(queryParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/fetch/human-resource/employees`, {
        ...options,
        params: queryParams,
      })
      .then((response) => resolve(response.data.data))
      .catch((error) => reject(error));
  });
}

function properties(payload) {
  let config = {
    ...options,
    params: payload,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(`/properties/employees`, config)
      .then((response) => {
        return resolve(response.data.resource);
      })
      .catch((error) => reject(error));
  });
}

function wages(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/human-resource/employees/${payload.uid}/wages-histories`, {
        ...options,
        params: payload.queryParams,
      })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        };
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function leaves(payload) {
  return new Promise((resolve, reject) => {
    const { uid, queryParams } = payload
    axios
      .get(`/human-resource/employees/${uid}/leaves-histories`, {
        ...options,
        params: queryParams,
      })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        };
        return resolve(response);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function list(queryParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/human-resource/employees`, {
        ...options,
        params: queryParams,
      })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        };
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function get(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/human-resource/employees/${payload.id}`, options)
      .then((response) => resolve(response.data.resource))
      .catch((error) => reject(error));
  });
}

function salaryDetails(payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/human-resource/employees/${payload.uid}/salary-details`,
        payload.details,
        options
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function paidMonthSalary(payload) {
  return new Promise((resolve, reject) => {
    axios.post(
     `/human-resource/employees/${payload.uid}/save-wage`,
     payload.salary,
     options
   ).then((response) => resolve(response.data))
   .catch((error) => reject(error));
  })
}

function saveLeave(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/human-resource/employees/${payload.uid}/save-leave`,
        payload.leave,
        options
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function updateLeave(payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/human-resource/employees/${payload.uid}/update-leave`,
        payload.details,
        options
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function add(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/human-resource/employees`, payload, options)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function update(payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/human-resource/employees/${payload.uid}`, payload.employee, options)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })
}

function destroy(userID) {
  return axios.delete(`/human-resource/employees/${userID}`, options);
}

export default {
  fetchAll,
  properties,
  list,
  wages,
  leaves,
  get,
  add,
  update,
  salaryDetails,
  saveLeave,
  updateLeave,
  paidMonthSalary,
  destroy,
};
