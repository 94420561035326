<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-file-manager">
        <div class="file-manager-app-menu">
          <!-- add file button start -->
          <div v-if="can('upload_archives', 'Bibliothèque') || can('create_folder', 'Bibliothèque')" class="dropdown dropdown-actions">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="add-file-btn p-0"
              block
            >
              <template #button-content>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn text-center"
                  type="button"
                  block
                >
                  <span class="align-middle"> Ajouter Nouveau</span>
                </b-button>
              </template>
              <b-dropdown-item
                v-if="can('create_folder', 'Bibliothèque')"
                id="toggle-add-folder-btn"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="$emit('toggleModal', 'folder')"
              >
                <feather-icon icon="FolderIcon" size="16" />
                <span class="align-middle ml-50">Dossier</span>
              </b-dropdown-item>
              <b-dropdown-item
              v-if="can('upload_archives', 'Bibliothèque') && canUploadFiles"
                id="toggle-add-file-btn"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="$emit('toggleModal', 'file')"
              >
                <feather-icon icon="UploadCloudIcon" size="16" />
                <span class="align-middle ml-50">Charger Fichier</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- add file button ends -->
          <!-- sidebar list items starts  -->
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-list scroll-area"
          >
            <!-- links for file manager sidebar -->

            <!-- Recents folder -->
            <b-list-group class="list-group list-group-labels">
              <div class="px-2 d-flex justify-content-between">
                <h6 class="section-label mb-1">Dossiers Récents</h6>
                <!-- <feather-icon icon="PlusIcon" /> -->
              </div>
              <b-list-group-item
                class="list-group-item"
                v-for="link in recentFolders"
                :key="link.slug"
                :active="isDynamicRouteActive(link.uid)"
                @click="openLink(link.uid)"
              >
                <feather-icon icon="FolderIcon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">{{
                  link.name
                }}</span>
              </b-list-group-item>
            </b-list-group>
            
            <!-- tree -->
            <!-- <ul class="list-group list-group-labels">
              <div class="px-2 d-flex justify-content-between">
                <h6 class="section-label mb-1">Tree</h6>
              </div>
              <sidebar-tree class="ml-3" :item="rootFolder" />
            </ul> -->
            <!-- links for file manager sidebar ends -->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BButton,
  BListGroup,
  BListGroupItem,
  VBModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import { computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from '@core/utils/utils'
import ability from '@/libs/acl/ability'

export default {
  name: "FileManagerSideBar",
  components: {
    VuePerfectScrollbar,
    BButton,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    // SidebarTree,
  },
  props: ["canUploadFiles"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup(props, emit) {
    const { router } = useRouter()
    const can = (action, resource) => {
      return ability.can(action, resource)
    }
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const recentFolders = computed(
      () => store.getters["files_manager/recentFolders"]
    );
    const rootFolder = computed(
      () => store.getters["files_manager/rootFolder"]
    );

    const openLink = (uid) => {
      router.push({name: 'folder-detail', params: {folder: uid}})
    }

    onMounted(() => {
      store.dispatch("files_manager/getRecents")
    })

    return {
      can,
      perfectScrollbarSettings,
      rootFolder,
      recentFolders,
      isDynamicRouteActive,

      openLink,
    };
  },
};
</script>
