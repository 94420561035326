import axios from '@axios'

const options = {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    },
};

function list(query) {
    let requestOptions = {
        ...options,
        params: query !== undefined ? query : null
    }
    return new Promise((resolve, reject) => {
        axios.get(`/files-manager/folders`, requestOptions)
            .then(response => {
                response = {
                    list: response.data.data,
                    meta: response.data.meta,
                }
                return resolve(response)
            })
            .catch(error => reject(error));
    })
}

function query(query) {
    let requestOptions = {
        ...options,
        params: query
    }
    return new Promise((resolve, reject) => {
        axios.get('/files-manager/medias-query', requestOptions)
            .then(response => resolve(response.data.data))
            .catch(error => reject(error));
    })
}

function get(payload) {
    return new Promise((resolve, reject) => {
        axios.get(`/files-manager/folders/${payload.id}`, options)
            .then(response => resolve(response.data.resource))
            .catch(error => reject(error));
    })
}

function recents() {
    return new Promise((resolve, reject) => {
        axios.get('/files-manager/recents', options)
            .then(response => resolve(response.data.data))
            .catch(error => reject(error));
    })
}

function add(payload) {
    return new Promise((resolve, reject) => {
        axios.post(`/files-manager/folders`, payload, options)
            .then(response => resolve(response.data.resource))
            .catch(error => reject(error));
    })
}

function update(payload) {
    return axios.put(`/files-manager/folders/${payload.id}`, payload, options)
        .then(response => response.data);
}

function destroy(folderID) {
    return axios.delete(`/files-manager/folders/${folderID}`, options)
}

function upload(payload, onProgress) {
    const bodyFormData = new FormData();
    // loop through content and append each file uploaded
    // payload.content.forEach((media, index) => {
    //     bodyFormData.append(`attachment[${index+1}]`, media);
    // });

    bodyFormData.append('attachment', payload.content); // append uploaded file
    options.onProgress = onProgress;

    options.onUploadProgress = progressEvent => {
        var progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        if(options.onProgress) options.onProgress(progress)
    };

    return new Promise((resolve, reject) => {
        axios.post(`/upload/folders/${payload.parent_id}/document`, bodyFormData, options)
            .then(response => resolve(response.data.url))
            .catch(error => reject(error));
    })
}

export default {
    list,
    get,
    query,
    recents,
    add,
    update,
    destroy,
    upload,
}