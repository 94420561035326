import service from "@/store/services/customers-service";

const state = {
  list: [],
  customer: {},
  meta: {},
  errors: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, customer) => {
    state.customer = customer;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
},
};

const actions = {
  async allCustomers({ commit, dispatch }, params) {
    try {
      const { clients } = await service.getClients(params)
      commit('SET_LIST', clients)
    } catch (error) {
      const data = error.data
      if (data != undefined && data.hasOwnProperty('errors')) commit('SET_ERRORS', { ...data.errors });
      return {...error}
    }
    return service
      .fetchAll(params)
      .then((customers) => customers)
      .catch((error) => error);
  },
  async fetchCustomers({ commit, dispatch }, params) {
    try {
        const { list, meta } = await service.list(params);
        commit('SET_LIST', list);
        commit('SET_META', meta);
        return { customers: list, total: meta.total }; 
    } catch (error) {
        return error
    }
  },
  fetchCustomer({ commit, dispatch }, params) {
    return service.get(params).then((customer) => {
      commit("SET_RESOURCE", customer);
      return customer;
    });
  },
  async addCustomer({ commit, dispatch }, params) {
    try {
      const response = await service.add(params)
      commit("SET_RESOURCE", response);
      return {success: true, data: response}
    } catch (error) {
      const data = error.data
      if (data != undefined && data.hasOwnProperty('errors')) commit('SET_ERRORS', { ...data.errors });
      return { success: false, status: error.status };
    }
  },
  updateCustomer({ commit, dispatch }, params) {
    return service.update(params).then((customer) => {
      commit("SET_RESOURCE", customer);
    });
  },
  destroyCustomer({ commit, dispatch }, params) {
    return service.destroy(params)
      .then(response => response)
      .catch(error => error);
  },
};

const getters = {
  all: (state) => state.list,
  customer: (state) => state.customer,
  meta: (state) => state.meta,
};

const customerModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default customerModule;
