import axios from '@axios'

import {
  options,
  defaultList,
  fetchAll,
  defaultAdd,
  defaultGet,
  defaultUpdate,
  defaultDestroy,
} from "@/store/services";

const link = "/finances/cash-registers";

function getAllCashRegisters() {
  return fetchAll('cash-registers');
}

function get(payload) {
  return defaultGet(link, payload)
}

function getProperties(payload) {
  let requestOptions = {
    ...options,
    params: payload
  }
  return new Promise((resolve, reject) => {
    axios.get('properties/cash-registers', requestOptions)
        .then(({ data }) => {
          return resolve(data.resource)
        })
        .catch(error => reject({...error}));
  })
}

function add(payload) {
  return defaultAdd(link, payload)
}

function update(payload) {
  return defaultUpdate(link, payload)
}

function destroy(crID) {
  return defaultDestroy(link, crID)
}

export default {
  getAllCashRegisters,
  get,
  add,
  update,
  destroy,
};