import jwtDefaultConfig from "@/@core/auth/jwt/jwtDefaultConfig";

export default class JwtService {
    axiosIns = null
    jwtConfig = {...jwtDefaultConfig }

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig }

        this.axiosIns.interceptors.request.use(
            (config) => {
                const accessToken = this.getToken()

                if (accessToken) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `${this.jwtConfig.tokenType} ${accessToken}`,
                    }
                }
                return config;
            },
            (error) => Promise.reject(error)
        )

    }
    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
    }

    me() {
        return this.axiosIns.get(this.jwtConfig.userEndpoint)
    }
}