<template>
  <b-sidebar
    id="app-file-manager-info-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Fichier
        </h5>

        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="TrashIcon"
            size="16"
            @click="hide"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>

      <!-- Body -->
      <div>Body</div>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
  },
}
</script>
