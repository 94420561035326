import service from "@/store/services/employees-service";

const state = {
  list: [],
  employee: {},
  meta: {},
  errors: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, employee) => {
    state.employee = employee;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  allEmployees({ commit, dispatch }, params) {
    return service
      .fetchAll(params)
      .then((employees) => employees)
      .catch((error) => error);
  },
  async fetchProperties({ commit, dispatch }, props) {
    try {
      const properties = await service.properties(props);
      return properties;
    } catch (error) {
      return error;
    }
  },
  async fetchEmployees({ commit, dispatch }, params) {
    try {
      const { list, meta } = await service.list(params);
      commit("SET_LIST", list);
      commit("SET_META", meta);
      return { employees: list, total: meta.total };
    } catch (error) {
      return error;
    }
  },
  async fetchEmployee({ commit, dispatch }, params) {
    const employee = await service.get(params);
    commit("SET_RESOURCE", employee);
    return employee;
  },
  async fetchWagesHistories({ commit, dispatch }, params) {
    try {
      const { list, meta } = await service.wages(params);
      commit("SET_LIST", list);
      commit("SET_META", meta);
      return { wages: list, total: meta.total };
    } catch (error) {
      return error;
    }
  },
  async fetchLeavesHistories({ commit, dispatch }, params) {
    try {
      const { list, meta } = await service.leaves(params);
      commit("SET_LIST", list);
      commit("SET_META", meta);
      return { leaves: list, total: meta.total };
    } catch (error) {
      return error;
    }
  },
  async addEmployee({ commit, dispatch }, params) {
    try {
      const response = await service.add(params);
      commit("SET_RESOURCE", response);
      return { success: true, data: response };
    } catch (error) {
      const data = error.data;
      if (data != undefined && data.hasOwnProperty("errors"))
        commit("SET_ERRORS", { ...data.errors });
      return { success: false, status: error.status };
    }
  },
  async updateSalaryDetails({ commit, dispatch }, params) {
    try {
      const response = await service.salaryDetails(params);
      commit("SET_RESOURCE", response);
      return { success: true, data: response };
    } catch (error) {
      const errorsData = error.response;
      if (errorsData != undefined && errorsData.hasOwnProperty("data"))
        commit("SET_ERRORS", { ...errorsData.data?.error });
      return { success: false, errors: errorsData.data?.error };
    }
  },
  async updateEmployee({ commit, dispatch }, params) {
    try {
      const response = await service.update(params);
      commit("SET_RESOURCE", response.resource);
      return ({ success: true, data: response.resource });
    } catch (error) {
      const errorsData = error.response;
      if (errorsData != undefined && errorsData.hasOwnProperty("data"))
        commit("SET_ERRORS", { ...errorsData.data?.error });
      return { success: false, errors: errorsData.data?.error };
    }
  },
  destroyEmployee({ commit, dispatch }, params) {
    return service.destroy(params);
  },
  async paidSalary({ commit, dispatch }, params) {
    try {
      const response = await service.paidMonthSalary(params);
      commit("SET_RESOURCE", data.resource);
      return ({ success: true, data: response.resource })
    } catch (error) {
      const errorsData = error.response;
      if (errorsData != undefined && errorsData.hasOwnProperty("data"))
        commit("SET_ERRORS", { ...errorsData.data?.error });
      return { success: false, errors: errorsData.data?.error };
    }
  },
  async addLeave({ commit, dispatch }, params) {
    try {
      const response = await service.saveLeave(params);
      commit("SET_RESOURCE", response);
      return ({ success: true, data: response });
    } catch (error) {
      const errorsData = error.response;
      if (errorsData != undefined && errorsData.hasOwnProperty("data"))
        commit("SET_ERRORS", { ...errorsData.data?.error });
      return { success: false, errors: errorsData.data?.error };
    }
  },
  async updateLeaveDetails({ commit, dispatch }, params) {
    try {
      const response = await service.updateLeave(params);
      commit("SET_RESOURCE", response);
      return ({ success: true, data: response });
    } catch (error) {
      const errorsData = error.response;
      if (errorsData != undefined && errorsData.hasOwnProperty("data"))
        commit("SET_ERRORS", { ...errorsData.data?.error });
      return { success: false, errors: errorsData.data?.error };
    }
  },
};

const getters = {
  all: (state) => state.list,
  employee: (state) => state.employee,
  meta: (state) => state.meta,
};

const employee = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default employee;
