import axios from "@axios";

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

function fetchAll(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/fetch/project-status`, options)
      .then((response) => resolve(response.data.resource))
      .catch(({ response }) => reject(response.data));
  });
}

function list(queryParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/settings/project-status`, {
        ...options,
        params: queryParams
      })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        };
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function get(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/settings/project-status/${payload.id}`, options)
      .then((response) => resolve(response.data.resource))
      .catch((error) => reject(error));
  });
}

function add(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/settings/project-status`, payload, options)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function update(payload) {
  return axios
    .put(`/settings/project-status/${payload.id}`, payload, options)
    .then((response) => response.data);
}

function destroy(projectStatus) {
  return axios
    .delete(`/settings/project-status/${projectStatus.id}`, options)
    .then(response => response)
    .catch(error => error);
}

export default {
  fetchAll,
  list,
  get,
  add,
  update,
  destroy,
};
