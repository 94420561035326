import axios from '@axios'

const options = {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    },
};

function fetchAll(params) {
    return new Promise((resolve, reject) => {
        axios.get(`/fetch/todos/${params.project}`, options)
            .then(response => resolve(response.data.resource))
            .catch(({ response }) => reject(response.data));
    });
}

function list(params) {
    return new Promise((resolve, reject) => {
        let config = {
            ...options,
            params: params
        };
        axios.get(`/todos`, config)
            .then(response => {
                response = {
                    list: response.data.data,
                    // meta: response.data.meta,
                };
                return resolve(response);
            })
            .catch(error => reject(error));
    })
}

function get(payload) {
    return new Promise((resolve, reject) => {
        axios.get(`/todos/${payload.id}`, options)
            .then(response => resolve(response.data.resource))
            .catch(error => reject(error));
    })
}

function add(payload) {
    return new Promise((resolve, reject) => {
        axios.post(`/todos`, payload, options)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    })
}

function update(payload) {
    return axios.put(`/todos/${payload.task.uid}`, payload.task, options)
        .then(response => response.data);
}

function destroy(payload) {
    return axios.delete(`/todos/${[payload.id]}`, options)
}

export default {
    fetchAll,
    list,
    get,
    add,
    update,
    destroy,
}