import service from "@/store/services/cash-registers-service";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  cash_register: { name: "", description: "" },
  meta: {},
  errors: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, cash_register) => {
    state.cash_register = cash_register;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  async allCashRegisters({ commit, dispatch }, params) {
    try {
      const list = await service.getAllCashRegisters(params);
      commit("SET_LIST", list);
    } catch (errors) {
      commit("SET_ERRORS", errors);
    }
  },
  async fetchCashRegister(ctx, cashRegisterID) {
    try {
      const cashRegister = await service.get({ id: cashRegisterID });
      ctx.commit("SET_RESOURCE", cashRegister);
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Un erreur est survenue lors de la récupération de la caisse."
      );
    }
  },
  async addCashRegister(ctx, params) {
    return service
      .add(params)
      .then((response) => {
        ctx.commit("SET_RESOURCE", response);
        response.message = "Votre caisse a été créée avec succès.";
        return response;
      })
      .catch(({ response }) => {
        handleErrors(ctx, response, "Un erreur est survenue lors de l'ajout de la caisse.");
      });
  },
  async updateCashRegister(ctx, params) {
    return service
      .update(params)
      .then((response) => {
        response.message = "Votre caisse a été modifiée avec succès.";
        ctx.commit("SET_RESOURCE", response.resource);
        return response;
      })
      .catch(({ response }) => {
        handleErrors(ctx, response, "Un erreur est survenue lors de la mise à jour de la caisse.")
      });
  },
  async destroyCashRegister({ commit, dispatch }, crID) {
    return service
      .destroy(crID)
      .then((response) => {
        commit(
          "SET_LIST",
          response.data?.resource ? response.data.resource : []
        );
        return response;
      })
      .catch(({ response }) => response);
  },
};

const getters = {
  all: (state) => state.list,
  cash_register: (state) => {
    return state.cash_register;
  },
  sub_cash_registers: (state) => state.cash_register.sub_cash_registers ?? [],
  meta: (state) => state.meta,
  errors: (state) => state.errors,
};

const cashRegister = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default cashRegister;
