import service from "@/store/services/product-brands-service";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  product_brand: { name: "", description: "" },
  meta: {},
  errors: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, product_brand) => {
    state.product_brand = product_brand;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  async allBrands(ctx, params) {
    try {
      return await service.fetchAllProductBrands();
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération des marques de produit."
      );
    }
  },

  async fetchBrandsList(ctx, params) {
    try {
      const { list, meta } = await service.list(params);
      ctx.commit("SET_LIST", list);
      ctx.commit("SET_META", meta);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération des marques de produit."
      );
    }
  },

  async fetchBrand(ctx, params) {
    try {
      const brand = await service.get(params);
      ctx.commit("SET_RESOURCE", brand);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération de la marque."
      );
    }
  },

  async addBrand(ctx, params) {
    try {
      const response = await service.add(params);
      ctx.commit("SET_RESOURCE", response);
      return ({ message: "Votre marque de produit a été enregistrée avec succès!" });
    } catch ({ response }) {
      handleErrors(ctx, response)
    }
  },
  async updateBrand(ctx, params) {
    try {
      const service = await service.update(params);
      ctx.commit("SET_RESOURCE", service.resource);
      return ({ message: "Votre marque de produit a été mis à jour avec succès."});
    } catch ({ response }) {
      handleErrors(ctx, response, "Une erreur est survenue lors de la mise à jour de la marque de produit")
    }
  },
  async destroyBrand({ commit, dispatch }, params) {
    //
  },
};

const getters = {
  all: (state) => state.list,
  product_brand: (state) => {
    return state.product_brand;
  },
  meta: (state) => state.meta,
  errors: (state) => state.errors,
};

const productBrand = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default productBrand;
