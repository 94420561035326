import service from '@/store/services/folders-service';
const flatFolders = (foldersCollection, folder) => {
    if(folder.children.length == 0) return foldersCollection;
    
    foldersCollection.forEach(f => {
        if(f.children.length > 0) foldersCollection.push(folder.children);
    })

    return ;

}
const state = {
    progress: 0,
    list: [],
    root: {
        name: 'Racine',
        children: [],
    },
    recents: [],
    query: [],
    folder: {},
    meta: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, folder) => {
        state.folder = folder;
    },
    SET_ROOT_TREE: (state, rf) => {
        state.root.children = rf;
    },
    // SET_RESOURCE_BY_UID: (state, id) => {
    //     if(state.list.length) {
    //         state.folder = state.list.find(f => f.uid === id);
    //     }
    // },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
    SET_RECENTS_FOLDER: (state, rcf) => {
        state.recents = rcf;
    },
    SET_PROGRESS: (state, progress) => {
        state.progress = progress;
    },
    SET_QUERY_FOLDER: (state, list) => {
        state.query = list;
    }
};

const actions = {
    list({ commit, dispatch }, params) {
        return service.list(params).then(
            ({ list, meta }) => {
                commit('SET_LIST', list);
                commit('SET_META', meta);
            }
        );
    },
    root({ commit, dispatch }) {
        return service.list({rootFolder: true}).then((rf) => { commit('SET_ROOT_TREE', rf.list); })
    },
    query({ commit, dispatch }, params) {
        return service.query(params).then((result) => result)
    },
    getRecents({ commit, dispatch }) {
        return service.recents().then((rcf) => {
            commit('SET_RECENTS_FOLDER', rcf);
        })
    },
    get({ commit, dispatch }, params) {
        return service.get(params).then((type) => { commit('SET_RESOURCE', type); })
    },
    add({ commit, dispatch }, params) {
        return service.add(params)
            .then((folder) => {
                // commit('SET_RESOURCE', folder);
            })
            .catch(error => error);
    },
    update({ commit, dispatch }, params) {
        return service.update(params)
            .then((folder) => { commit('SET_RESOURCE', folder); });
    },
    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    },
    upload({ commit, dispatch }, params) {
        return service.upload(params, (progress) => {
            commit('SET_PROGRESS', progress);
        }).then((folder) => {
            commit('SET_RESOURCE', folder);
        });
    }
};

const getters = {
    all: (state) => state.list,
    recentFolders: (state) => {
        // let children = []
        // state.list.forEach(folder => {
        //     if (folder.children.length) {
        //         children.push(...folder.children)
        //     }
        // })
        // children.sort(
        //     (f1, f2) => Date.parse(f2.updated_at) - Date.parse(f1.updated_at)
        // )

        // return children.slice(0, 4)
        return state.recents
    },
    rootFolder: (state) => state.root,
    folder: (state) => state.folder,
    meta: (state) => state.meta,
    progress: (state) => state.progress,
};

const files_manager = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
export default files_manager;