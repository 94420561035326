import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ability from "@/libs/acl/ability";

const { default: useJwt, default: jwt } = require("@/auth/jwt/useJwt");

/**
 * Get user abilities
 * @param user
 * @returns array
 */
const userAbility = (user) => {
  let abilities = [];
  user.ability.forEach((a) => {
    if (a.is_active) {
      abilities.push({
        action: a.key,
        subject: a.module.name,
      });
    }
  });
  return abilities;
};

export default {
  namespaced: true,
  state: {
    isAuthenticated: localStorage.getItem("accessToken") !== null,
    me: null,
    isFetchingUser: false,
    isOnline: true,
  },

  getters: {
    me: (state) => state.me,
    fetchingUser: (state) => state.isFetchingUser,
    ability: (state) => userAbility(state.me),
    authorizedMenus: (state) => (menus) => {
      const user = state.me;
      let currMenus = [];

      if (user !== null && user?.ability !== undefined) {
        let userModules = userAbility(user).map((a) => a.subject);
        const uniqueUserModules = [...new Set(userModules)];

        currMenus = menus.reduce((accumulator, currentMenu) => {
          if (
            (currentMenu.title !== "Tableau de Bord" &&
              uniqueUserModules.includes(currentMenu.title)) ||
            currentMenu.hasOwnProperty("header")
          ) {
            accumulator.push(currentMenu);
            return accumulator;
          }
          return accumulator;
        }, []);
      }

      return currMenus;
    },
    isOnline: (state) => state.isOnline,
  },

  mutations: {
    SET_RESOURCE: (state, me) => {
      state.me = me;
    },
    SET_FETCHING_STATE: (state, status) => {
      state.isFetchingUser = status;
    },
    SET_ONLINE_STATUS: (state, status) => {
      state.isOnline = status
    }
  },

  actions: {
    login(ctx, payload) {
      const companyApi = JSON.parse(localStorage.getItem("COMPANY_INFO"));
      const baseURL = process.env[`VUE_APP_API_${companyApi?.name}_BASE_URL`];

      jwt.axiosIns.defaults.baseURL = baseURL;
      return jwt
        .login(payload)
        .then((response) => {
          const data = response.data.resource;
          useJwt.setToken(data.accessToken);
          ctx.commit("SET_RESOURCE", data.user);
          return data.user;
        })
        .then((user) => {
          // Assign ability
          ability.update(userAbility(user));

          return user;
        })
        .then((user) => {
          router.replace(getHomeRouteForLoggedInUser(user.role?.slug));
          return { success: true, data: user };
        })
        .catch(({ response }) => ({
          success: false,
          data: response.data,
        }));
    },
    me({ commit }) {
      commit("SET_FETCHING_STATE", true);
      return useJwt
        .me()
        .then((profile) => {
          const user = profile.data.data;

          // Update ability
          ability.update(userAbility(user));
          commit("SET_RESOURCE", user);
          commit("SET_FETCHING_STATE", false);
        })
        .catch(({ message, response }) => {
          if (
            (message == "Network Error" && response == undefined) ||
            (response &&
              response?.data.message == "Unauthenticated." &&
              response?.status == 401)
          ) {
            localStorage.clear();
            router.push({ name: "login" });
          }
        });
    },
    updateInfo({ commit, dispatch }, payload) {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .put("auth/me", payload, options)
          .then(({ data }) => {
            const user = data.resource;

            // Update ability
            ability.update(userAbility(user));
            commit("SET_RESOURCE", user);
            return resolve(user);
          })
          .catch(({ response }) => {
            return reject(response);
          });
      });
    },
    logout() {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      return new Promise((resolve, reject) => {
        axios
          .post("auth/logout", options)
          .then((response) => resolve(response))
          .catch((response) => reject(response));
      });
    },
    async checkOnlineStatus({ commit }) {
      try {
        const online = await fetch("/1pixel.png");
        commit("SET_ONLINE_STATUS", (online.status >= 200 && online.status < 300)); // either true or false
      } catch (error) {
        commit("SET_ONLINE_STATUS", false); // definitly offline
      }
    }
  },
};
