import axios from "@axios";

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};


function fetchOnlyParents() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/fetch/only-parent-projects`, options)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

function fetchAll() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/fetch/projects`, options)
      .then((response) => {
        return resolve(response.data.data);
      })
      .catch((error) => reject(error));
  });
}

function properties(payload) {
  let config = {
    ...options,
    params: payload,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(`/properties/projects`, config)
      .then((response) => {
        return resolve(response.data.resource);
      })
      .catch((error) => reject(error));
  });
}

function expenses(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`finances/projects/${payload.projectId}/expenses-reduce`, options)
      .then((response) => {
        return resolve(response.data.data);
      })
      .catch((error) => reject(error));
  });
}

function payments(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`finances/projects/${payload.projectId}/payments-status`, options)
      .then((response) => {
        return resolve(response.data.resource);
      })
      .catch((error) => reject(error));
  });
}

function budget(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`finances/projects/${payload.id}/trackings`, options)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

function list(queryParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/projects`, {
        ...options,
        params: queryParams,
    })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        };
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function get(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/projects/${payload.id}`, options)
      .then((response) => resolve(response.data.resource))
      .catch((error) => reject(error));
  });
}

function add(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/projects`, payload, options)
      .then((response) => resolve(response.data))
      .catch(({ response }) => reject(response));
  });
}

function update(payload) {
  return axios
    .put(`/projects/${payload.uid}`, payload, options)
    .then((response) => response.data);
}

function destroy(projectID) {
  return axios.delete(`/projects/${projectID}`, options);
}

export default {
  fetchAll,
  fetchOnlyParents,
  list,
  properties,
  expenses,
  budget,
  payments,
  get,
  add,
  update,
  destroy,
};
