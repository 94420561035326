import JwtService from './jwtService'
import axios from '@axios'

const config = {
    loginEndpoint: '/auth/login',
    userEndpoint: '/auth/me',
    registerEndpoint: '/auth/register',
    refreshEndpoint: '/auth/refresh-token',
    logoutEndpoint: '/auth/logout',
}
const jwt = new JwtService(axios, config)

export default jwt