<template>
  <b-modal
    v-model="openModal"
    centered
    title="Ajouter un nouveau dossier"
    ok-only
    ok-title="Enregistrer"
    @hidden="openModal = false"
    @ok="onSubmit"
  >
    <b-card-text>
      <b-form @submit.prevent>
        <b-row>
          <!-- Nom du Dossier -->
          <b-col cols="12">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="FolderIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-folder"
                  v-model="mediaLocal.content"
                  placeholder="Nom du Dossier"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BCardText,
  BInputGroup,
  BFormInput,
  BForm,
  BInputGroupPrepend,
  BFormGroup,
} from "bootstrap-vue";
import { ref, computed, toRefs } from "@vue/composition-api";
import useMediaHandler from "../useMediaHandler";
export default {
  name: "AddFolderHandler",
  components: {
    BRow,
    BCol,
    BModal,
    BCardText,
    BInputGroup,
    BFormInput,
    BForm,
    BInputGroupPrepend,
    BFormGroup,
  },
  props: ["media", "showContent"],
  setup(props, { emit }) {
    const { mediaLocal, resetFolderLocal, onSubmit } = useMediaHandler(
      toRefs(props),
      emit
    );
    const openModal = computed({
      get() {
        return props.showContent
      },
      set(value) {
        emit('update:showContent', value)
      }
    });
    return {
      mediaLocal,
      openModal,
      onSubmit,
    };
  },
};
</script>
