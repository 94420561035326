import service from "@/store/services/sub-cash-registers-service";
import { status } from "@/utils/constants/httpStatusCode";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  cash_register: null,
  sub_cash_register: { name: "", description: "", fund_type_id: null },
  meta: {},
  fund_types: [],
  managers: [],
  payment_methods: [],
  errors: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_FUND_TYPES: (state, fund_types) => {
    state.fund_types = fund_types;
  },
  SET_MANAGERS: (state, managers) => {
    state.managers = managers;
  },
  SET_PAYMENT_METHODS: (state, payment_methods) => {
    state.payment_methods = payment_methods;
  },
  SET_RESOURCE: (state, sub_cash_register) => {
    state.sub_cash_register = sub_cash_register;
  },
  SET_MAIN_CASH_REGISTER: (state, cash_register) => {
    state.cash_register = cash_register;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  async fetchSubCashRegisterProperties(ctx, params) {
    try {
      const { fund_types, payment_methods, managers } = await service.getProperties(params);

      if(fund_types) ctx.commit("SET_FUND_TYPES", fund_types);
      if(payment_methods) ctx.commit("SET_PAYMENT_METHODS", payment_methods);
      if(managers) ctx.commit("SET_MANAGERS", managers);
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Un erreur est survenue lors de la récupération de la caisse."
      );
    }
  },
  async addSubCashRegister(ctx, params) {
    return service
      .add(params)
      .then((response) => {
        ctx.commit("SET_RESOURCE", response);
        response.message = "Votre sous caisse a été créée avec succès.";
        return response;
      })
      .catch(({ response }) => {
        handleErrors(ctx, response)
      });
  },
  async updateSubCashRegister({ commit, dispatch }, params) {
    return service
      .update(params)
      .then((response) => {
        response.message = "Votre sous caisse a été modifiée avec succès.";
        commit("SET_RESOURCE", response.resource);
        return response;
      })
      .catch(({ response }) => {
        commit("SET_ERRORS", {
          error:
            response.status !== status.UNPROCESSABLE_ENTITY
              ? {}
              : response.data.error,
          message:
            "Un erreur est survenue lors de la mise à jour de la sous caisse.",
        });
      });
  },
  async destroySubCashRegister({ commit, dispatch }, params) {
    return service
      .destroy(params)
      .then((response) => response)
      .catch((error) => error);
  },
};

const getters = {
  all: (state) => state.list,
  sub_cash_register: (state) => {
    return state.sub_cash_register;
  },
  cash_register: (state) => state.cash_register,
  meta: (state) => state.meta,
  fund_types: (state) => [
    {
      name: "Veuillez sélectionner un type de caisse",
      slug: "nothing_selected",
    },
    ...state.fund_types,
  ],
  managers: (state) => state.managers,
  errors: (state) => state.errors,
};

const subCashRegister = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default subCashRegister;
