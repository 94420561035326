import axios from '@axios'

export const options = {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    },
};

export function defaultList(link, query) {
  let requestOptions = {
      ...options,
      params: query !== undefined ? query : null
  }
  return new Promise((resolve, reject) => {
      axios.get(link, requestOptions)
          .then(response => {
              response = {
                  list: response.data.data,
                  meta: response.data.meta,
              }
              return resolve(response)
          })
          .catch(error => reject(error));
  })
}


export function fetchAll(customLink) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/fetch/${customLink}`, options)
            .then((response) => resolve(response.data.resource))
            .catch(({ response }) => reject(response.data));
    });
}

export function defaultQuery(link, query) {
  let requestOptions = {
      ...options,
      params: query
  }
  return new Promise((resolve, reject) => {
      axios.get(link, requestOptions)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error));
  })
}

export function defaultGet(link, payload) {
  return new Promise((resolve, reject) => {
      axios.get(`${link}/${payload.id}`, options)
          .then(response => resolve(response.data.resource))
          .catch(error => reject(error));
  })
}

export function defaultAdd(link, payload) {
  return new Promise((resolve, reject) => {
      axios.post(link, payload, options)
          .then(response => resolve(response.data.resource))
          .catch(error => reject(error));
  })
}

export function defaultUpdate(link, payload) {
  return axios.put(`${link}/${payload.id}`, payload, options)
      .then(response => response.data);
}

export function defaultDestroy(link, folderID) {
  return axios.delete(`${link}/${folderID}`, options)
}

export function defaultUpload(link, payload, onProgress) {
  const bodyFormData = new FormData();
  // loop through content and append each file uploaded
  // payload.content.forEach((media, index) => {
  //     bodyFormData.append(`attachment[${index+1}]`, media);
  // });

  bodyFormData.append('attachment', payload.content); // append uploaded file
  options.onProgress = onProgress;

  options.onUploadProgress = progressEvent => {
      var progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
      if(options.onProgress) options.onProgress(progress)
  };

  return new Promise((resolve, reject) => {
      axios.post(`${link}/${payload.parent_id}/document`, bodyFormData, options)
          .then(response => resolve(response.data.url))
          .catch(error => reject(error));
  })
}