import axios from '@axios'

const options = {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    },
};

function list(params) {
    let requestOptions = {
        ...options,
        params: params
    }
    return new Promise((resolve, reject) => {
        axios.get(`/finances/fund-histories`, requestOptions)
            .then(response => response.data)
            .then(fundHistories => {
                let data = {
                    list: fundHistories.data,
                    meta: fundHistories.meta,
                    balance: fundHistories.balance,
                }
                return resolve(data)
            })
            .catch(({response}) => reject(response));
    })
}

function get(payload) {
    return new Promise((resolve, reject) => {
        axios.get(`/finances/fund-histories/${payload.id}`, options)
            .then(response => resolve(response.data.resource))
            .catch(error => reject(error));
    })
}

function loadCashRegister() {
    return new Promise((resolve, reject) => {
        axios.get('/fetch/cash-registers/default', options)
            .then(response => resolve(response.data.resource))
            .catch(error => reject(error));
    })
}

function fetchAccounts() {
    return new Promise((resolve, reject) => {
        axios.get('/fetch/cash-registers/accounts', options)
            .then(response => resolve(response.data.resource))
            .catch(error => reject(error));
    })
}

function add(payload) {
    return new Promise((resolve, reject) => {
        axios.post(`/finances/fund-histories`, payload, options)
            .then(response => resolve(response.data))
            .catch(({ response }) => reject(response));
    })
}

function providingSubCR(payload) {
    return new Promise((resolve, reject) => {
        axios
          .put(
            `/finances/providing/balance/${payload.uid}`,
            payload.data,
            options
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject({ ...error }));
    })
}

function update(payload) {
    return axios.put(`/finances/fund-histories/${payload.uid}`, payload.data, options)
        .then(response => response.data);
}

function destroy(fundHistoryId) {
    return axios.delete(`/finances/fund-histories/${fundHistoryId}`, options)
}

function statistics(payload) {
    return axios.get('/finances/statistics', {params: payload}, options)
}

export default {
    list,
    get,
    add,
    update,
    destroy,
    statistics,
    loadCashRegister,
    fetchAccounts,
    providingSubCR,
}