import Vue from 'vue'
import jwtDefaultConfig from "@/@core/auth/jwt/jwtDefaultConfig";

// axios
import axios from 'axios'
const companyApi = JSON.parse(localStorage.getItem('COMPANY_INFO'))

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env[`VUE_APP_API_${companyApi?.name}_BASE_URL`],
    // baseURL: process.env.VUE_APP_API_BASE_URL,
    // timeout: 1000,
    headers: { 'Bearer': localStorage.getItem(jwtDefaultConfig.storageTokenKeyName) }
})
Vue.prototype.$http = axiosIns

export default axiosIns