import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils';
import { canNavigate } from '@/libs/acl/routeProtection';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn();
    // console.log('is user logged in', isLoggedIn)
    // console.log(`can user navigate to: "${to.path}"`, canNavigate(to))
    if(to.name !== 'login') {
        if(!isLoggedIn) {
            return next({ name: 'login' })
        } else if (isLoggedIn && !canNavigate(to)) {
            // If logged in && not authorized
            return next({ name: 'not-authorized' });
        }
    }
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData();
        next(getHomeRouteForLoggedInUser(userData ? userData.role?.slug : null));
    }
    next();
})

export default router;