import axios from "@axios";

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

function fetchAll(queryParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/fetch/users`, {
        ...options,
        params: queryParams
      })
      .then((response) => resolve(response.data.data))
      .catch((error) => reject(error));
  });
}

function properties(payload) {
  let config = {
    ...options,
    params: payload,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(`/properties/users`, config)
      .then((response) => {
        return resolve(response.data.resource);
      })
      .catch((error) => reject(error));
  });
}

function list(queryParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/users`, {
        ...options,
        params: queryParams
      })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        };
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function get(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/users/${payload.id}`, options)
      .then((response) => resolve(response.data.resource))
      .catch((error) => reject(error));
  });
}

function add(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/users`, payload, options)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function update(payload) {
  return axios
    .put(`/users/${payload.uid}`, payload, options)
    .then((response) => response.data);
}

function destroy(userID) {
  return axios.delete(`/users/${userID}`, options);
}

export default {
  fetchAll,
  properties,
  list,
  get,
  add,
  update,
  destroy,
};
