import service from "@/store/services/taxes-service";

const state = {
  list: [],
  tax: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, tax) => {
    state.tax = tax;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  allTaxes({ commit, dispatch }, params) {
    return service
      .fetchAll(params)
      .then((tax) => tax)
      .catch((error) => error);
  },
  async fetchTaxesList({ commit, dispatch }, params) {
    try {
        const { list, meta } = await service.list(params);
        commit('SET_LIST', list);
        commit('SET_META', meta);
        return { taxes: list, total: meta.total }; 
    } catch (error) {
        return error
    }
  },

  fetchTax({ commit, dispatch }, params) {
    return service.get(params).then((tax) => {
      commit("SET_RESOURCE", tax);
      return tax;
    });
  },
  addTax({ commit, dispatch }, params) {
    return service
      .add(params)
      .then((response) => {
        commit("SET_RESOURCE", response);
      })
      .catch((error) => error);
  },
  updateTax({ commit, dispatch }, params) {
    return service.update(params).then((tax) => {
      commit("SET_RESOURCE", tax);
    });
  },
  destroyTax({ commit, dispatch }, params) {
    return service.destroy(params)
      .then(response => response)
      .catch(error => error);
  },
};

const getters = {
  all: (state) => state.list,
  tax: (state) => state.tax,
  meta: (state) => state.meta,
};

const tax = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default tax;
