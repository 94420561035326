import axios from "@axios";

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

function fetchAll(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/fetch/roles`, options)
      .then((response) => resolve(response.data.resource))
      .catch(({ response }) => reject(response.data));
  });
}

function list(queryParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/roles`, {
        ...options,
        params: queryParams
      })
      .then((response) => {
        response = {
          list: response.data.data,
          meta: response.data.meta,
        };
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function modules() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/fetch/modules`, options)
      .then((response) => {
        response = response.data.resource;
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
}

function get(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/roles/${payload.id}`, options)
      .then((response) => resolve(response.data.resource))
      .catch((error) => reject(error));
  });
}

function add(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/roles`, payload, options)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

function update(payload) {
  return axios
    .put(`/roles/${payload.id}`, payload, options)
    .then((response) => response.data);
}

function toggleStatus(payload) {
  return axios
    .put(`toggle/roles/${payload.id}`, { is_checked: payload.checked }, options)
    .then((response) => response.data);
}

function toggleModule(payload) {
  return axios
    .put(
      `toggle/roles/${payload.id}/modules`,
      {
        module: payload.module,
        is_attached: payload.is_attached,
      },
      options
    )
    .then(({ data }) => data.resource )
    .catch(error => error);
}

function destroy(role) {
  return axios
    .delete(`/roles/${role.id}`, options)
    .then(response => response)
    .catch(error => error);
}

export default {
  fetchAll,
  list,
  modules,
  get,
  add,
  update,
  toggleStatus,
  toggleModule,
  destroy,
};
