import service from "@/store/services/project-status-service";

const state = {
  list: [],
  projectStatus: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, projectStatus) => {
    state.projectStatus = projectStatus;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  allProjectStatus({ commit, dispatch }, params) {
    return service
      .fetchAll(params)
      .then((projectStatus) => projectStatus)
      .catch((error) => error);
  },
  async fetchProjectStatusList({ commit, dispatch }, params) {
    try {
        const { list, meta } = await service.list(params);
        commit('SET_LIST', list);
        commit('SET_META', meta);
        return { project_status: list, total: meta.total }; 
    } catch (error) {
        return error
    }
  },

  fetchProjectStatus({ commit, dispatch }, params) {
    return service.get(params).then((projectStatus) => {
      commit("SET_RESOURCE", projectStatus);
      return projectStatus;
    });
  },
  addProjectStatus({ commit, dispatch }, params) {
    return service
      .add(params)
      .then((response) => {
        commit("SET_RESOURCE", response);
      })
      .catch((error) => error);
  },
  updateProjectStatus({ commit, dispatch }, params) {
    return service.update(params).then((projectStatus) => {
      commit("SET_RESOURCE", projectStatus);
    });
  },
  destroyProjectStatus({ commit, dispatch }, params) {
    return service.destroy(params)
      .then(response => response)
      .catch(error => error);
  },
};

const getters = {
  all: (state) => state.list,
  projectStatus: (state) => state.projectStatus,
  meta: (state) => state.meta,
};

const projectStatus = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default projectStatus;
