import store from '@/store'
import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import { isUserLoggedIn } from '@/auth/utils';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

let existingAbility = initialAbility;
if(isUserLoggedIn) {
  existingAbility = store.getters['auth/ability'];
}

export default new Ability(existingAbility);