import service from '@/store/services/users-service';

const state = {
    list: [],
    user: {},
    meta: {},
    errors: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, user) => {
        state.user = user;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
    SET_ERRORS: (state, errors) => {
        state.errors = errors;
    },
};

const actions = {
    allUsers({ commit, dispatch }, params) {
        return service.fetchAll(params).then(users => users).catch(error => error);
    },
    async fetchProperties({ commit, dispatch }, props) {
        try {
            const properties = await service.properties(props);
            return properties;
        } catch (error) {
            return error;
        }
    },
    async fetchUsers({ commit, dispatch }, params) {
        try {
            const { list, meta } = await service.list(params);
            commit('SET_LIST', list);
            commit('SET_META', meta);
            return { users: list, total: meta.total };
        } catch (error) {
            return error;
        }
    },
    async fetchUser({ commit, dispatch }, params) {
        const user = await service.get(params);
        commit('SET_RESOURCE', user);
        return user;
    },
    async addUser({ commit, dispatch }, params) {
        try {
            const response = await service.add(params);
            commit('SET_RESOURCE', response);
            return { success: true, data: response };
        } catch (error) {
            const data = error.data
            if (data != undefined && data.hasOwnProperty('errors')) commit('SET_ERRORS', { ...data.errors });
            return { success: false, status: error.status };
        }
    },
    updateUser({ commit, dispatch }, params) {
        return service.update(params)
            .then((data) => {
                commit('SET_RESOURCE', data.resource);
                return data
            });
    },
    destroyUser({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};


const getters = {
  all: (state) => state.list,
  user: (state) => state.user,
  meta: (state) => state.meta,
};

const user = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
export default user;