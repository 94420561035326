import service from '@/store/services/todos-service';

const state = {
    list: [],
    todo: {},
    meta: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, todo) => {
        state.todo = todo;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    }
};

const actions = {
    allTasks({ commit, dispatch }, params) {
        return service.fetchAll(params).then((tasks) => tasks).catch(error => error);
    },
    fetchTasks({ commit, dispatch }, params) {
        return service.list(params).then(
            ({ list, meta }) => {
                commit('SET_LIST', list);
                // commit('SET_META', meta);

                return list;
            }
        )
            .catch(error => error);
    },
    fetchTask({ commit, dispatch }, params) {
        return service.get(params).then((todo) => {
            commit('SET_RESOURCE', todo);
            return todo;
        })
    },
    addTask({ commit, dispatch }, params) {
        return service.add(params)
            .then((response) => {
                commit('SET_RESOURCE', response);
            })
            .catch(error => error);
    },
    updateTask({ commit, dispatch }, params) {
        return service.update(params)
            .then((todo) => {
                commit('SET_RESOURCE', todo);
            });
    },
    destroyTask({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};


const getters = {
  all: (state) => state.list,
  todo: (state) => state.todo,
  meta: (state) => state.meta,
};

const todo = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
export default todo;