import axios from "@axios";

import {
  options,
  defaultList,
  fetchAll,
  defaultAdd,
  defaultGet,
  defaultUpdate,
  defaultDestroy,
} from "@/store/services";

const link = "/finances/sub-cash-registers";

function get(payload) {
  return defaultGet(link, payload);
}

function getProperties(payload) {
  let requestOptions = {
    ...options,
    params: payload,
  };

  return new Promise((resolve, reject) => {
    axios
      .get("properties/sub-cash-registers", requestOptions)
      .then(({ data }) => {
        return resolve(data.resource);
      })
      .catch((error) => reject({ ...error }));
  });
}

function add(payload) {
  const customLink = `/finances/cash-registers/${payload.cash_register}/sub-cash-registers`;
  return defaultAdd(customLink, payload.data);
}

function update(payload) {
  const customLink = `/finances/cash-registers/${payload.cash_register}/sub-cash-registers`;
  return defaultUpdate(customLink, { id: payload.id, ...payload.data });
}

function destroy(folderID) {
  return defaultDestroy(link, folderID);
}

export default {
  getProperties,
  get,
  add,
  update,
  destroy,
};
