import { ref, watch } from "@vue/composition-api";

function useMediaHandler(props, emit) {
  const mediaLocal = ref(JSON.parse(JSON.stringify(props.media.value)));
  const mediaFile = ref(null);

  const resetMediaLocal = () => {
    mediaLocal.value = JSON.parse(JSON.stringify(props.media.value));
  };

  watch(props.media, () => {
    resetMediaLocal();
  });

  const onSubmit = () => {
    const mediaData = JSON.parse(JSON.stringify(mediaLocal));

    if (props.media.uid) emit("update-media", mediaData.value);
    else emit("add-media", mediaData.value);
  };
  const onloadFile = () => {
    emit("add-media", {});
  };

  return {
    mediaLocal,
    mediaFile,
    resetMediaLocal,
    onSubmit,
    onloadFile,
  };
}

export default useMediaHandler;
