import service from '@/store/services/projects-service';

const state = {
    list: [],
    project: {},
    budget: {},
    expenses: [],
    paymentsStatus: [],
    meta: {},
    errors: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, project) => {
        state.project = project;
    },
    SET_EXPENSES: (state, expenses) => {
        state.expenses = expenses;
    },
    SET_PAYMENTS_STATUS: (state, paymentsStatus) => {
        state.paymentsStatus = paymentsStatus;
    },
    SET_BUDGET_TRACKING: (state, bt) => {
        state.budget = bt;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
    SET_ERRORS: (state, errors) => {
        state.errors = errors;
    },
};

const actions = {
    onlyParents({ commit, dispatch }) {
        return service.fetchOnlyParents().then((list) => list).catch(error => error);
    },
    allProjects({ commit, dispatch }) {
        return service.fetchAll().then((list) => list).catch(error => error);
    },
    async fetchProjects({ commit, dispatch }, params) {
        try {
            const { list, meta } = await service.list(params);
            commit('SET_LIST', list);
            commit('SET_META', meta);
            return { projects: list, total: meta.total };
        } catch (error) {
            return error;
        }
    },
    async fetchBudget({ commit, dispatch }, params) {
        const budget_tracking = await service.budget(params);
        commit('SET_BUDGET_TRACKING', budget_tracking.resource);
        return budget_tracking;
    },
    async fetchProject({ commit, dispatch }, params) {
        const project = await service.get(params);
        commit('SET_RESOURCE', project);
        return project;
    },
    async fetchProperties({ commit, dispatch }, props) {
        try {
            const properties = await service.properties(props);
            return properties;
        } catch (error) {
            return error;
        }
    },
    async fetchExpenses({ commit, dispatch }, params) {
        try {
            const expenses = await service.expenses(params);
            commit('SET_EXPENSES', expenses)
        } catch (error) {
            return error;
        }
    },
    async fetchPaymentsStatus({ commit, dispatch }, params) {
        try {
            const payments = await service.payments(params);
            commit('SET_PAYMENTS_STATUS', payments)
        } catch (error) {
            return error;
        }
    },
    async addProject({ commit, dispatch }, params) {
        try {
            const response = await service.add(params);
            commit('SET_RESOURCE', response);
            return { success: true, data: response };
        } catch (error) {
            const data = error.data
            if (data != undefined && data.hasOwnProperty('errors')) commit('SET_ERRORS', { ...data.errors });
            return { success: false, status: error.status };
        }
    },
    updateProject({ commit, dispatch }, params) {
        return service.update(params)
            .then((data) => {
                commit('SET_RESOURCE', data.resource);
                return data
            });
    },
    destroyProject({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};

const getters = {
    expenses: (state) => state.expenses,
    paymentsStatus: (state) => state.paymentsStatus
};

const project = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
export default project;