import service from "@/store/services/roles-service";

const state = {
  list: [],
  role: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, role) => {
    state.role = role;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  allRoles({ commit, dispatch }, params) {
    return service
      .fetchAll(params)
      .then((roles) => roles)
      .catch((error) => error);
  },
  async fetchRoles({ commit, dispatch }, params) {
    try {
        const { list, meta } = await service.list(params);
        commit('SET_LIST', list);
        commit('SET_META', meta);
        return { roles: list, total: meta.total }; 
    } catch (error) {
        return error
    }
  },
  async fetchModules({ commit, dispatch }, params) {
    try {
        const modules = await service.modules(params);
        return modules; 
    } catch (error) {
        return error
    }
  },
  fetchRole({ commit, dispatch }, params) {
    return service.get(params).then((role) => {
      commit("SET_RESOURCE", role);
      return role;
    });
  },
  addRole({ commit, dispatch }, params) {
    return service
      .add(params)
      .then((response) => {
        commit("SET_RESOURCE", response);
      })
      .catch((error) => error);
  },
  updateRole({ commit, dispatch }, params) {
    return service.update(params).then((role) => {
      commit("SET_RESOURCE", role);
    });
  },
  toggleRoleStatus({ commit, dispatch }, params) {
    return service.toggleStatus(params).then((role) => {
      commit("SET_RESOURCE", role);
    });
  },
  toggleRoleModules({ commit, dispatch }, params) {
    return service.toggleModule(params).then((role) => {
      commit("SET_RESOURCE", role);
      return role;
    });
  },
  destroyRole({ commit, dispatch }, params) {
    return service.destroy(params)
      .then(response => response)
      .catch(error => error);
  },
};

const getters = {
  all: (state) => state.list,
  role: (state) => state.role,
  meta: (state) => state.meta,
};

const userGroup = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default userGroup;
