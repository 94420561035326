import service from "@/store/services/product-categories-service";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  product_category: null,
  meta: {},
  errors: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, product_category) => {
    state.product_category = product_category;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  async allCategories(ctx, params) {
    try {
      return await service.fetchAllProductCategories();
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération des catégories de produit."
      );
    }
  },
  
  async fetchCategoriesList(ctx, params) {
    try {
      const { list, meta } = await service.list(params);
      ctx.commit("SET_LIST", list);
      ctx.commit("SET_META", meta);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération des catégories de produit."
      );
    }
  },

  async fetchCategory(ctx, params) {
    try {
      const category = await service.get(params);
      ctx.commit("SET_RESOURCE", category);
    } catch ({ response }) {
      handleErrors(
          ctx,
          response,
          "Un erreur est survenue lors de la récupération de la catégorie."
      );
    }
  },

  async addCategory(ctx, params) {
    try {
      const response = await service.add(params);
      ctx.commit("SET_RESOURCE", response);
      return ({ message: "Votre catégorie de produit a été enregistrée avec succès!" });
    } catch ({ response }) {
      handleErrors(ctx, response)
    }
  },
  async updateCategory(ctx, params) {
    try {
      const service = await service.update(params);
      ctx.commit("SET_RESOURCE", service.resource);
      return ({ message: "Votre catégorie de produit a été mis à jour avec succès."});
    } catch ({ response }) {
      handleErrors(ctx, response, "Une erreur est survenue lors de la mise à jour de la catégorie de produit")
    }
  },
  async destroyCategory({ commit, dispatch }, params) {
    //
  },
};

const getters = {
  all: (state) => state.list,
  product_category: (state) => {
    return state.product_category;
  },
  meta: (state) => state.meta,
  errors: (state) => state.errors,
};

const productCategory = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default productCategory;
