<template>
  <b-modal
    id="modal-add-file"
    v-model="openModal"
    centered
    title="Charger un nouveau ficher"
    size="lg"
    ok-only
    ok-title="Fermer"
    @hidden="openModal = false"
    @ok="closeUploader"
  >
    <uploader :options="options" class="uploader-example">
      <uploader-unsupport></uploader-unsupport>
      <uploader-drop>
        <p>Déposez les fichiers ici pour les enregistrer ou</p>
        <uploader-btn>Selectionnez les fichiers</uploader-btn>
        <uploader-btn :attrs="attrs">Selectionnez les images</uploader-btn>
        <!-- <uploader-btn :directory="true">select folder</uploader-btn> -->
      </uploader-drop>
      <uploader-list></uploader-list>
    </uploader>
  </b-modal>
</template>

<script>
import jwtDefaultConfig from "@/@core/auth/jwt/jwtDefaultConfig";
import { computed, toRefs } from "@vue/composition-api";
import useMediaHandler from '../useMediaHandler';

// base url
const companyApi = JSON.parse(localStorage.getItem("COMPANY_INFO"));
const currBaseURL = process.env[`VUE_APP_API_${companyApi?.name}_BASE_URL`];

// token
const token = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName);
const uploadRequestHeaders = {
  Authorization: "Bearer " + token,
  Bearer: token,
};
export default {
  name: "AddFileHandler",
  props: ["parentId", "media", "showContent"],
  setup(props, { emit }) {
    const openModal = computed({
      get() {
        return props.showContent;
      },
      set(value) {
        emit("update:showContent", value);
      },
    });

    const { onloadFile } = useMediaHandler(toRefs(props), emit)

    const parentUid = computed(() => {
      return props.parentId;
    });

    // const fileStatus = (status, response) => {
    //   console.log({
    //     'status': status,
    //     'response': response
    //   })
    //   const statusTextMap = {
    //     success: "Succès",
    //     error: "Erreur",
    //     uploading: "En chargement",
    //     paused: "Pause",
    //     waiting: "En attente",
    //   }
    //   return statusTextMap[status]
    // }
    const uploadUrl =
    currBaseURL + "/upload/folders/" + parentUid.value + "/document"
    const chunkSize = 5 * 1024 * 1024 // 5MB
    const maxFileSize = 1024 * 1024 * 1024 // 1GB
    const options = {
      target: uploadUrl,
      // chunkSize: chunkSize,
      totalSize: maxFileSize,
      headers: { ...uploadRequestHeaders },
      simultaneousUploads: 1,
      testChunks: false,
      // fileStatusText: fileStatus(),
    }
    const attrs = {
      accept: "image/*",
    }

    const closeUploader = () => {
      openModal.value = false
      onloadFile()
    }

    return {
      parentUid,
      closeUploader,
      openModal,
      options,
      attrs,
    };
  },
};
</script>
<style>
/* .uploader-example {
  width: 880px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
} */
</style>
