<template>
  <div class="file-manager-application" style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <!-- file manager app content starts -->
    <div class="file-manager-main-content">
      <!-- search area start -->
      <div
        id="search-container"
        class="file-manager-content-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <!-- Toggler -->
          <div
            class="sidebar-toggle d-block d-xl-none float-left align-middle ml-1"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>
          <!-- Searchbar -->
          <div
            class="d-flex align-content-center justify-content-between w-100"
          >
            <b-input-group
              class="input-group-merge shadow-none m-0 flex-grow-1"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input
                id="popover-reactive-1"
                class="files-filter border-0 bg-transparent"
                v-model="searchInput"
                placeholder="Rechercher"
              />
            </b-input-group>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <!-- <div class="file-actions">
            <feather-icon
              icon="ArrowDownCircleIcon"
              class="cursor-pointer d-sm-inline-block d-none mr-50"
            />
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer d-sm-inline-block d-none mr-50"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.app-file-manager-info-sidebar
            >
              <feather-icon
                icon="AlertCircleIcon"
                class="cursor-pointer d-sm-inline-block d-none mr-50"
              />
            </b-button>
            <div class="dropdown d-inline-block">
              <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
                <template #button-content>
                  <feather-icon
                    id="fileActions"
                    role="button"
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon
                    icon="MoveIcon"
                    size="16"
                    class="align-middle text-body mr-50"
                  />
                  Plus d'option
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div> -->
          <!-- Toggle view -->
          <div
            class="btn-group btn-group-toggle view-toggle ml-50"
            data-toggle="buttons"
          >
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="btn-radios"
                v-model="viewToggle"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                size="sm"
                button-variant="outline-primary"
                buttons
              >
                <b-form-radio value=""
                  ><feather-icon icon="GridIcon"
                /></b-form-radio>
                <b-form-radio value="list-view"
                  ><feather-icon icon="ListIcon"
                /></b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <!-- Breadcrumb start -->
      <folder-breadcrumb class="d-flex align-items-center" />
      <!-- Breadcrumb end -->

      <!-- search area ends here -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="file-manager-content-body scroll-area"
      >
        <b-overlay :show="isLoading" rounded="sm" style="height: 50vh">
          <router-view :data-view="viewToggle" />
          <template #overlay>
            <div class="text-center">
              <feather-icon icon="ClockIcon" size="24" />
              <b-card-text id="cancel-label">
                Veuillez Patienter...
              </b-card-text>
              <b-progress show-progress animated height="1.3rem">
                <b-progress-bar
                  :value="uploadPercentage"
                  :label="`${((uploadPercentage / 100) * 100).toFixed(0)} %`"
                ></b-progress-bar>
              </b-progress>
            </div>
          </template>
        </b-overlay>
      </vue-perfect-scrollbar>
    </div>
    <!-- file manager app content ends -->

    <!-- File Info Sidebar Starts-->
    <file-sidebar-info />
    <!-- File Info Sidebar Ends -->

    <!-- Add New File Modal start -->
    <component
      :media="media"
      :is="adderModal"
      :parentId="parendId"
      :showContent.sync="openModal"
      @add-media="addMedia"
      @update-media="updateMedia"
    />
    <!-- Add New File Modal Ends -->

    <!-- popover to display query result start -->
    <b-popover
      id="query-result"
      ref="popover"
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="bottomright"
      container="search-container"
    >
    <!-- header of popover -->
      <!-- <template v-slot:title>
        <div class="d-flex justify-content-between align-items-center">
          <span>Résultat de la recherche</span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="close"
            variant="transparent"
            aria-label="Fermer"
            @click="onQueryOk"
          >
            <span class="d-inline-block text-white" aria-hidden="true"
              >&times;</span
            >
          </b-button>
        </div>
      </template> -->
      
      <!-- content of popover -->
      <div>
        <i class="text-muted" v-if="isSearching">Recherche en cours...</i>
        <div class="my-1" v-else>
          <b-list-group v-if="queryResult.length > 0">
            <b-list-group-item
              v-for="folder in queryResult"
              :key="folder.uid"
              class="btn-link"
              @click="openFolder(folder.uid)"
              button
            >
              <div class="d-flex flex-row justify-content-between text-left">
                <div>
                <feather-icon icon="FolderIcon" />
                <span class="card-text btn-link ml-2">{{
                  folder.name.toUpperCase()
                }}</span>
                </div>
                <small class="file-accessed text-muted"
                  >Dernière modification: {{ formatDate(folder.updated_at) }}</small
                >
              </div>
            </b-list-group-item>
          </b-list-group>
          <i class="text-muted" v-else>Aucun dossier correspondant à votre recherche.</i>
        </div>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
          @click="onQueryOk"
        >
          Fermer
        </b-button>
      </div>
    </b-popover>
    <!-- popover to display query result end -->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <file-manager-sidebar
        @toggleModal="toggleAdderModal"
        :canUploadFiles="isSubFolder"
        :class="{ show: mqShallShowLeftSidebar }"
      />
    </portal>
  </div>
</template>

<script>
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import {
  BOverlay,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BCardText,
  BProgress,
  BProgressBar,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BPopover,
  BAlert,
  BFormSelect,
  BCard,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { useRouter } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import FileSidebarInfo from "./sidebar/FileSidebarInfo.vue";
import FileManagerSidebar from "./sidebar/FileManagerSidebar.vue";
import FormFileHandler from "./handlers/forms/FileHandler.vue";
import FormFolderHandler from "./handlers/forms/FolderHandler.vue";
import FolderBreadcrumb from "./contents/FolderBreadcrumb.vue";

import moment from 'moment'
moment.locale('fr')

export default {
  name: "FileManagerLayout",
  components: {
    BOverlay,
    BProgress,
    BProgressBar,
    BCardText,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormRadio,
    BPopover,
    BAlert,
    BFormSelect,
    BCard,
    BFormRadioGroup,
    VuePerfectScrollbar,
    FileSidebarInfo,
    FileManagerSidebar,
    FormFileHandler,
    FormFolderHandler,
    FolderBreadcrumb,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  setup() {
    const { route, router } = useRouter();
    const routeParams = computed(() => route.value.params);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const formatDate = (date) => moment(new Date(date).getTime()).fromNow()

    const medias = ref([]);
    const queryResult = ref([]);
    const formOption = ref("");
    const blankMedia = {
      content: null,
      parent_id: null,
    };

    watch(routeParams, () => {
      fetchMedias();
      // getParentUid();
    });

    const isLoading = ref(false);
    const isSearching = ref(false);
    const uploadPercentage = computed(() => {
      return store.getters["files_manager/progress"];
    });
    const viewToggle = ref("");
    const searchInput = ref(null);
    const popoverShow = ref(false);
    const openModal = ref(false);
    const currentModalName = ref("form-folder-handler");

    const media = JSON.parse(JSON.stringify(blankMedia));
    const adderModal = computed(() => {
      return currentModalName.value;
    });

    //check if we are on main folder or sub-folder
    const isSubFolder = computed(() => {
      return route.value.name == "folder-detail";
    });

    watch(searchInput, () => {
      if (searchInput.value && searchInput != "") {
        queryMedias();
      }
    });

    const onQueryOk = () => {
      popoverShow.value = false;
      searchInput.value = null;
    };

    const openFolder = (uid) => {
      searchInput.value = null;
      popoverShow.value = false;
      router.push({name: 'folder-detail', params: {folder: uid}})
    };

    const toggleAdderModal = (option) => {
      formOption.value = option;

      if (option == "file") {
        currentModalName.value = "form-file-handler";
      } else {
        currentModalName.value = "form-folder-handler";
      }
      openModal.value = true;
    };

    const queryMedias = () => {
      isSearching.value = true;
      store
        .dispatch("files_manager/query", { query: searchInput.value })
        .then((result) => {
          queryResult.value = result;
          isSearching.value = false;
        });
    };

    //get folders list
    const fetchMedias = () => {
      if (isSubFolder.value) {
        let params = { id: route.value.params.folder };
        store.dispatch("files_manager/get", params)
      } else {
        store.dispatch("files_manager/list").then(() => {
          medias.value = store.getters["files_manager/all"];
        });
      }
      isLoading.value = false;
    };
    const fetchRootFolder = () => {
      store.dispatch("files_manager/root");
    };
    const getParentUid = computed(() => {
      let parentUid = null;
      if (isSubFolder.value) {
        parentUid = route.value.params.folder;
      }
      return parentUid;
    });

    // add folder request  to the API
    const addMedia = (params) => {
      isLoading.value = true;
      let newMedia = {
        parent_id: getParentUid.value,
      };

      if (formOption.value == "folder") {
        Object.assign(newMedia, { name: params.content });
        store.dispatch("files_manager/add", newMedia).then(() => {
          store.dispatch("files_manager/getRecents"); // to update recents folder
          fetchMedias();
        });
      } else if (formOption.value == "file") {
        fetchMedias();
      }
      formOption.value = "";
    };
    // update folder
    const updateMedia = (params) => {
      isLoading.value = true;
      store.dispatch("files_manager/update", params).then(() => {
        fetchMedias();
      });
      formOption.value = "";
    };

    fetchMedias();
    fetchRootFolder();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      parendId: getParentUid,
      uploadPercentage,
      isLoading,
      isSearching,
      popoverShow,
      formOption,
      isSubFolder,
      viewToggle,
      searchInput,
      queryResult,
      media,
      medias,
      adderModal,
      openModal,
      toggleAdderModal,
      fetchRootFolder,
      fetchMedias,
      addMedia,
      updateMedia,
      perfectScrollbarSettings,
      mqShallShowLeftSidebar,

      onQueryOk,
      formatDate,
      openFolder,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-file-manager.scss";
#query-result.popover {
  max-width: 70% !important;
  width: 70% !important;
}
</style>
