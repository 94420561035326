import axios from '@axios'

import {
  options,
  defaultList,
  defaultAdd,
  defaultGet,
  defaultUpdate,
  defaultDestroy,
} from "@/store/services";

const link = "/finances/invoices";

function list(query) {
  return defaultList(link, query);
}

function get(payload) {
  return defaultGet(link, payload)
}

function getProperties(payload) {
  let requestOptions = {
    ...options,
    params: payload
  }
  return new Promise((resolve, reject) => {
      axios.get('properties/invoices', requestOptions)
          .then(({ data }) => {
              return resolve(data.resource)
          })
          .catch(error => reject({...error}));
  })
}

function getProjects(payload) {
  return new Promise((resolve, reject) => {
      axios.get(`fetch/invoices/customers/${payload.client_id}/projects`, options)
          .then(({ data }) => {
              return resolve(data.resource)
          })
          .catch(error => reject({...error}));
  })
}

function getBudget(payload) {
  return new Promise((resolve, reject) => {
      axios.get(`finances/real-tracking/${payload.project_uid}`, options)
          .then(({ data }) => {
              return resolve(data.resource)
          })
          .catch(error => reject({...error}));
  })
}

function add(payload) {
  return defaultAdd(link, payload)
}

function update(payload) {
  return defaultUpdate(link, payload)
}

function destroy(folderID) {
  return defaultDestroy(link, folderID)
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  getProperties,
  getProjects,
  getBudget,
};
