import service from "@/store/services/stores-management-service";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  store: {},
  meta: {},
  errors: null,
  cartItemsCount: (() => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
  })(),
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, store) => {
    state.store = store;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, error) => {
    state.errors = error;
  },
  UPDATE_CART_ITEMS_COUNT(state, count) {
    state.cartItemsCount = count
  },
};

const actions = {
  async allStores(ctx, params) {
    try {
      const service = await service.fetchAllStores(params);
      return service;
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Un erreur est survenue lors de la récupération des magasins."
      );
    }
  },
  async fetchStoresList(ctx, params) {
    try {
      const { list, meta } = await service.list(params);
      ctx.commit("SET_LIST", list);
      ctx.commit("SET_META", meta);
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Un erreur est survenue lors de la récupération des magasins."
      );
    }
  },

  async fetchStore(ctx, params) {
    try {
      const storeData = await service.get(params);
      ctx.commit("SET_RESOURCE", storeData);
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Un erreur est survenue lors de la récupération du magasin."
      );
    }
  },

  async addStore(ctx, params) {
    try {
      const response = await service.add(params);
      ctx.commit("SET_RESOURCE", response);
      return ({ message: "Votre magasin a été enregistré avec succès!" });
    } catch ({ response }) {
      handleErrors(ctx, response)
    }
  },
  async updateStore(ctx, params) {
    try {
      const service = await service.update(params);
      ctx.commit("SET_RESOURCE", service.resource);
      return ({ message: "Votre magasin a été mis à jour avec succès."});
    } catch ({ response }) {
      handleErrors(ctx, response, "Une erreur est survenue lors de la mise à jour du magasin")
    }
  },
  async destroyStore({ commit, dispatch }, params) {
    //
  },

  async fetchItems(ctx, payload) {
    try {
      const response = await service.storeStocks(payload);
      return response;
    } catch (error) {
      return error;
    }
  },
  async fetchItem(ctx, { productId }) {
    try {
      const response = await service.stockItem(productId)
      return response;
    } catch (error) {
      return error;
    }
  },

  async fetchQueueItems({ commit, dispatch }, params) {
    try {
      const items = await service.queueItems(params);
      return items;
    } catch (error) {
      return error;
    }
  },

  async fetchCartItems({ commit, dispatch }, params) {
    try {
      const items = await service.cartItems(params);
      return items;
    } catch (error) {
      return error;
    }
  },

  async addItemToQueue(ctx, { productId }) {
    try {
      const response = await service.addItemToQueue(productId);
      return response;
    } catch (error) {
      return error;
    }
  },

  async removeItemFromQueue(ctx, { productId }) {
    try {
      const response = await service.removeItemFromQueue(productId);
      return response;
    } catch (error) {
      return error;
    }
  },

  async addItemInCart(ctx, { productId }) {
    try {
      const response = await service.addItemInCart(productId);
      return response;
    } catch (error) {
      return error;
    }
  },

  async removeItemFromCart(ctx, { productId }) {
    try {
      const response = await service.removeItemFromCart(productId);
      return response;
    } catch (error) {
      return error;
    }
  },
};

const getters = {
  all: (state) => state.list,
  store: (state) => state.store,
  meta: (state) => state.meta,
  errors: (state) => state.errors,
};

const storeManagement = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default storeManagement;
