import { status } from "@/utils/constants/httpStatusCode";

export const handleErrors = function (
  ctx,
  response,
  message = "Un erreur est survenue lors de la requête."
) {
  if (response) {
    ctx.commit("SET_ERRORS", {
      error:
        response.status !== status.UNPROCESSABLE_ENTITY
          ? {}
          : response.data.error,
      message: message,
    });
  }
};
