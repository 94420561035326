<template>
<div class="breadcrumbs-top shadow-lg bg-seondary">
  <div class="breadcrumb-wrapper w-1">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'files-manager' }">
        <feather-icon
          icon="HomeIcon"
          size="16"
          class="align-text-top"
        />
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
  <div class="breadcrumb-wrapper w-auto overflow-auto mr-2">
    <b-breadcrumb class="d-flex flex-nowrap">
      <b-breadcrumb-item
        class=" text-nowrap"
        v-for="item in folderTree"
        :key="item.name"
        :active="item.active"
        :to="item.uid"
      >
        {{ item.name }}
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</div>
</template>

<script>
import store from '@/store'
import { computed, watch } from '@vue/composition-api'
import {
  BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'FolderBreadcrumb',
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
  },
  setup() {
    const folderTree = computed(() => {
      const folder = store.getters['files_manager/folder']
      if(Object.keys(folder).length > 0) return folder.folder_tree
      return []
    })
    return {
      folderTree,
    }
  }
}
</script>
